<template>
  <div>
    <van-cell-group inset style="margin-top: 10px">
      <van-cell title="行程名称" size="large">
        <template #default>
          <div v-if="!edit">梁山环线</div>
          <van-field v-if="edit" type="text" label="" placeholder="请输入名称" v-model="invoiceName"/>
        </template>
      </van-cell>
      <van-cell title="行程详情" size="large">
        <template #label>
          <van-steps active="false" direction="vertical" >
            <van-step v-for="(item, index) in invoice" :key="index" @click-step="confirmDeleteStep(index)">
              <van-cell :title="item" clickable @click="confirmDeleteStep(index)">
              </van-cell>
            </van-step>
            <van-step v-if="edit">
              <van-cell clickable @click="showAddPanel = true">
                <template #title>
                  <van-icon name="plus" />
                </template>
              </van-cell>
            </van-step>
          </van-steps>
        </template>
      </van-cell>
    </van-cell-group>
    
  </div>
</template>

<script>
export default {
  data() {
    return {
      invoice: ["高山草地", "余村", "笔架山"],
    }
  }
}
</script>

<style>

</style>